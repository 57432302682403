import React, { useEffect } from 'react';
import getChartColorsArray from "../../../../Common/ChartsDynamicColor";
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const Bubble = ({ dataColors }: any) => {
  var chartBubbleSimpleColors = getChartColorsArray(dataColors);
  const generateData = (baseval: any, count: any, yrange: any) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

      series.push([x, y, z]);
      baseval += 86400000;
      i++;
    }
    return series;
  };

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName('apex-charts');
  }, [layoutThemeType]);

  const series = [{
    name: 'Bubble1',
    data: generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  },
  {
    name: 'Bubble2',
    data: generateData(new Date('12 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  },
  {
    name: 'Bubble3',
    data: generateData(new Date('13 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  },
  {
    name: 'Bubble4',
    data: generateData(new Date('14 Feb 2017 GMT').getTime(), 20, {
      min: 10,
      max: 60
    })
  }
  ];
  var options: any = {

    chart: {
      height: 350,
      type: 'bubble',
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 0.8
    },
    title: {
      text: 'Simple Bubble Chart',
      style: {
        fontWeight: 500,
      },
    },
    xaxis: {
      tickAmount: 12,
      type: 'category',
    },
    yaxis: {
      max: 70
    },
    colors: chartBubbleSimpleColors
  };

  return (
    <React.Fragment>
      <Chart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bubble"
        height={350}
      />
    </React.Fragment>
  );
};

const ThreeDBubble = ({ dataColors }: any) => {
  var chartBubbleColors = getChartColorsArray(dataColors);
  const generateData = (baseval: any, count: any, yrange: any) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

      series.push([x, y, z]);
      baseval += 86400000;
      i++;
    }
    return series;
  };

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);
  useEffect(() => {
    document.getElementsByClassName('apex-charts');
  }, [layoutThemeType]);

  const series = [
    {
      name: "Product1",
      data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
        min: 10,
        max: 60,
      }),
    },
    {
      name: "Product2",
      data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
        min: 10,
        max: 60,
      }),
    },
    {
      name: "Product3",
      data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
        min: 10,
        max: 60,
      }),
    },
    {
      name: "Product4",
      data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
        min: 10,
        max: 60,
      }),
    },
  ];

  const options: any = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    title: {
      text: "3D Bubble Chart",
      style: {
        fontWeight: 500,
      },
    },
    xaxis: {
      tickAmount: 12,
      type: "datetime",
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      max: 70,
    },
    theme: {
      palette: "palette2",
    },
    colors: chartBubbleColors,
  };
  return (
    <React.Fragment>
      <Chart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="bubble"
        height={350}
      />
    </React.Fragment>
  );
};

export {
  Bubble,
  ThreeDBubble
};