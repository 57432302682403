import React, { useState, useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

// Import Components
import ActiveUsers from "./activeUsers";
import Sale from "./sale";
import BrowserUsage from "./browserUsage";
import TopPages from "./topPages";
import SalesReport from "./salesReport";
import Earning from "./Earning";
import { Stacked2, Negative } from "pages/Components/apexcharts/Bar/Bar";
import { DiffColorTreemap } from "pages/Components/apexcharts/Treemap/Treemap";
import { RangeAreaBasicChartCode } from "pages/Components/apexcharts/RangeAreaCharts/RangeAreaCharts";
import { Datetime } from "pages/Components/apexcharts/Scatter/Scatter";
import { ScrollVertical } from "pages/Components/tables/DataTables/Tabledata";
import DataTable from "react-data-table-component";
import axios from "axios";

interface PopulationItem {
  male: number;
  // Add other fields if necessary
}

interface Props {
  populationByAge: PopulationItem[];
}

const Analytics = () => {
  const [selectedValue, setSelectedValue] = useState("1");
  const [aimagPopulation, setAimagPopulation] = useState([]);
  const [totalVoted, setTotalVoted] = useState(0);
  const [populationByAge, setPopulationByAge] = useState([]);
  const [cats, setCats] = useState<any>();
  const [male, setMale] = useState<any>([]);
  const [female, setFemale] = useState<any>([]);

  const [sumPopulationAge, setSumPopulationAge] = useState<any>();
  const [allNames, setAllNames] = useState<any>();
  const [age1822, setAge1822] = useState<any>();
  const [age5054, setAge5054] = useState<any>();
  const [age2329, setAge2329] = useState<any>();
  const [age3034, setAge3034] = useState<any>();
  const [age3539, setAge3539] = useState<any>();
  const [age70, setAge70] = useState<any>();
  const [age4044, setAge4044] = useState<any>();
  const [age5559, setAge5559] = useState<any>();
  const [age6064, setAge6064] = useState<any>();
  const [age4549, setAge4549] = useState<any>();
  const [age6569, setAge6569] = useState<any>();

  const handleCheckboxChange = (value: any) => {
    setSelectedValue(value);
  };
  const getWrestlingJackpot = async () => {
    try {
      const jackpotRes = await axios.post(
        "https://zeer.space/aimag/age/election/dornod",
        {
          jsonrpc: 2,
          params: {},
        }
      );
      setAimagPopulation(jackpotRes?.data?.result);
      // console.log(jackpotRes, "jack ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPeopleByAge = async () => {
    try {
      const PeopleByAgeRes = await axios.post(
        "https://zeer.space/aimag/age_total/election/dornod",
        {
          jsonrpc: 2.0,
          params: {},
        }
      );

      setPopulationByAge(PeopleByAgeRes?.data?.result);
      // console.log(PeopleByAgeRes, "PeopleByAgeRes ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getSumPopulationByAge = async () => {
    try {
      const res = await axios.post(
        "https://zeer.space/aimag/age_person/aimag",
        {
          jsonrpc: 2.0,
          params: {
            aimag_id: "1",
          },
        }
      );

      setSumPopulationAge(res?.data?.result);
      // console.log(res, "sumand nasnii buleg ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const callNewAimagInfo = async (id: any) => {
    try {
      const jackpotRes2 = await axios.post(
        "https://zeer.space/aimag/age/election/aimag",
        {
          jsonrpc: 2,
          params: {
            aimag_id: id,
          },
        }
      );
      setAimagPopulation(jackpotRes2?.data?.result);
      // console.log(jackpotRes2, "jack ress");
    } catch (error) {
      console.error("Error:", error);
    }

    try {
      const PeopleByAgeRes2 = await axios.post(
        "https://zeer.space/aimag/age_total/election/aimag",
        {
          jsonrpc: 2.0,
          params: { aimag_id: id },
        }
      );

      setPopulationByAge(PeopleByAgeRes2?.data?.result);
      // console.log(PeopleByAgeRes2, "PeopleByAgeRes ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getWrestlingJackpot();
    getPeopleByAge();
    getSumPopulationByAge();
  }, []);
  useEffect(() => {
    callNewAimagInfo(selectedValue);
    // console.log("Selected value changed:", selectedValue);
  }, [selectedValue]);
  useEffect(() => {
    let total = 0;
    aimagPopulation.forEach((item: any) => {
      total += item.populattion;
    });
    setTotalVoted(total);
  }, [aimagPopulation]);
  useEffect(() => {
    const newCats: any[] = [];
    const newMale: any[] = [];
    const newFemale: any[] = [];

    populationByAge.forEach((item: any) => {
      newCats.push(item?.name);
      newMale.push(item?.male);
      newFemale.push(-item?.female);
    });

    setCats(newCats);
    setMale(newMale);
    setFemale(newFemale);
  }, [populationByAge]);

  useEffect(() => {
    if (!sumPopulationAge) return;

    const keysArray: string[] = Object.keys(sumPopulationAge);

    // const allNamesArray: string[] = keysArray.reduce(
    //   (acc: string[], key: string) => {
    //     sumPopulationAge[key].line.forEach((item: { name: string }) => {
    //       if (!acc.includes(item.name)) {
    //         acc.push(item.name);
    //       }
    //     });
    //     return acc;
    //   },
    //   []
    // );

    const names1822Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "18-22") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names2329Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "23-29") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names3034Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "30-34") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names3539Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "35-39") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names70Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "70+") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names4044Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "40-44") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names5559Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "55-59") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names6064Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "60-64") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names4549Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "45-49") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    const names6569Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "65-69") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );

    const names5054Array: number[] = Object.keys(sumPopulationAge).reduce(
      (acc: number[], key: string) => {
        sumPopulationAge[key].line.forEach(
          (item: { name: string; count: number }) => {
            if (item.name === "50-54") {
              acc.push(item.count);
            }
          }
        );
        return acc;
      },
      []
    );
    setAllNames(keysArray);
    setAge5054(names5054Array);
    setAge1822(names1822Array);
    setAge2329(names2329Array);
    setAge3034(names3034Array);
    setAge3539(names3539Array);
    setAge70(names70Array);
    setAge4044(names4044Array);
    setAge5559(names5559Array);
    setAge6064(names6064Array);
    setAge4549(names4549Array);
    setAge6569(names6569Array);
    // console.log(keysArray, " 1-r");
    // console.log(names5054Array, "2-r");
  }, [sumPopulationAge]);

  // table iin utga
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Сум</span>,
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Нийт хүн ам</span>,
      selector: (row: any) => row.populattion,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Санал өгсөн</span>,
      selector: (row: any) => row.voted,
      sortable: true,
    },
  ];

  document.title = "Дашбоард | Зээр систем";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Аймаг сонгох" pageTitle="Dashboards" />
          <Row>
            <Col xl={8}>
              <Row>
                {/* <Widgets /> */}
                <Col>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        value="1"
                        checked={selectedValue === "1"}
                        onChange={() => handleCheckboxChange("1")}
                      />
                      Дорнод аймаг
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        value="2"
                        checked={selectedValue === "2"}
                        onChange={() => handleCheckboxChange("2")}
                      />
                      Хэнтий аймаг
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        value="3"
                        checked={selectedValue === "3"}
                        onChange={() => handleCheckboxChange("3")}
                      />
                      Сүхбаатар аймаг
                    </label>
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      display: "inline-block",
                      textAlign: "center",
                      fontSize: "24px",
                    }}
                  >
                    <div>Нийт санал өгөх иргэн</div>
                    <div style={{ fontWeight: "bold" }}>{totalVoted}</div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  {/* <Overview /> */}
                  <Negative
                    data={[
                      {
                        name: "Эрэгтэй",
                        data: male,
                      },
                      {
                        name: "Эмэгтэй",
                        data: female,
                      },
                    ]}
                    dataColors='["--tb-primary", "--tb-success"]'
                    categ={cats}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={4}>
              <div style={{ overflowX: "auto" }}>
                <DataTable
                  fixedHeader
                  fixedHeaderScrollHeight="400px"
                  columns={columns}
                  data={aimagPopulation}
                />
              </div>
            </Col>
            <Row>
              <Row>
                <DiffColorTreemap
                  data={[
                    {
                      data: aimagPopulation?.map((item: any) => ({
                        x: item?.name,
                        y: item?.populattion,
                      })),
                    },
                  ]}
                  dataColors='["--tb-primary", "--tb-secondary", "--tb-success", "--tb-info","--tb-warning", "--tb-danger"]'
                />
              </Row>
              <Row>
                <Stacked2
                  data={[
                    {
                      name: "18-22",
                      data: age1822,
                    },
                    {
                      name: "23-29",
                      data: age2329,
                    },
                    {
                      name: "30-34",
                      data: age3034,
                    },
                    {
                      name: "35-39",
                      data: age3539,
                    },
                    {
                      name: "40-44",
                      data: age4044,
                    },
                    {
                      name: "45-49",
                      data: age4044,
                    },
                    {
                      name: "45-49",
                      data: age4549,
                    },
                    {
                      name: "50-54",
                      data: age5054,
                    },
                    {
                      name: "55-59",
                      data: age5559,
                    },
                    {
                      name: "60-64",
                      data: age6064,
                    },
                    {
                      name: "65-69",
                      data: age6569,
                    },
                    {
                      name: "70+",
                      data: age70,
                    },
                  ]}
                  categ={allNames}
                  dataColors='["--tb-primary", "--tb-success", "--tb-warning", "--tb-danger", "--tb-info"]'
                />
              </Row>
            </Row>

            {/* <Row>
              <Datetime dataColors='["--tb-primary", "--tb-success", "--tb-warning", "--tb-warning", "--tb-info"]' />
            </Row> */}
          </Row>

          {/* <Row>
              <Col xl={4}>
                <Sale />
              </Col>
              <Col xl={4}>
                <SalesReport />
              </Col>
              <Col xl={4}>
                <Earning />
              </Col>
            </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
