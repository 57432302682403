import BreadCrumb from 'Common/BreadCrumb'
import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'

const PrivacyPolicy = () => {

    document.title = "Privacy Policy | Steex - Admin & Dashboard Template"

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Privacy Policy" pageTitle="Pages" />
                    <Row className=" justify-content-center">
                        <Col xxl={9}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary pb-0">
                                    <Card.Body className="text-center py-4">
                                        <h4 className="fw-medium text-white mb-3">Privacy Policy</h4>
                                        <p className="text-white text-opacity-75 mb-0">To learn more about Privacy at themesbrand please visit our <a href="#!" className="text-white text-decoration-underline">Privacy Hub</a>.</p>
                                    </Card.Body>
                                    <Card.Body className="py-2 bg-white bg-opacity-10">
                                        <ul className="list-unstyled mb-0 text-white-75 hstack gap-2 justify-content-between">
                                            <li>
                                                Our privacy policy hes been updated
                                            </li>
                                            <li>
                                                25, March 2023
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </div>
                                <Card.Body>
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Getting Stared with Steex</h6>
                                    <p className="text-muted mb-2">At Website Name, accessible at Website.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Website Name and how we use it.</p>
                                    <p className="text-muted mb-2">Your privacy statement should be clear, direct, and easy to understand.</p>
                                    <p className="text-muted mb-2">Keep technical jargon and legal terminology to a minimum.</p>
                                    <p className="text-muted mb-2">How we use your information:</p>
                                    <ul className="text-muted">
                                        <li>
                                            <p className="mb-2">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomized words which don't look even slightly believable.</p>
                                        </li>
                                        <li>
                                            <p className="mb-2">If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                        </li>
                                        <li>
                                            <p className="mb-2">On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.</p>
                                        </li>
                                        <li>
                                            <p className="mb-2">It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                        </li>
                                    </ul>
                                    <p className="text-muted mb-2">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at <b>steex@themesbrand.com</b></p>
                                    <p className="text-muted">This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Website Name. This policy is not applicable to any information collected offline or via channels other than this website.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> How we use your information</h6>
                                    <p className="text-muted mb-2">Right to Privacy: Every individual has his own secrets, talks, planning etc. which they don not want to show, tell and discuss with others. So right to privacy plays a important role in the freedom and liberty of a person and if our fundamental right given under Article 21 of Indian Constitution, 1950.</p>
                                    <p className="text-muted mb-2">Security is about the safeguarding of data, whereas privacy is about the safeguarding of user identity.</p>
                                    <p className="text-muted mb-2">If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                                    <p className="text-muted mb-2">The specific differences, however, are more complex, and there can certainly be areas of overlap between the two. Security refers to protection against the unauthorized access of data.</p>
                                    <p className="text-muted mb-2">We use the information we collect in various ways, including to:</p>
                                    <ul className="text-muted vstack gap-2">
                                        <li>
                                            Provide, operate, and maintain our website
                                        </li>
                                        <li>
                                            Improve, personalize, and expand our website
                                        </li>
                                        <li>
                                            Understand and analyze how you use our website
                                        </li>
                                        <li>
                                            Develop new products, services, features, and functionality
                                        </li>
                                        <li>
                                            Send you emails
                                        </li>
                                        <li>
                                            Find and prevent fraud
                                        </li>
                                    </ul>
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Our role in your privacy</h6>
                                    <p className="text-muted mb-2">Privacy is essential to who we are as human beings, and we make decisions about it every single day. It gives us a space to be ourselves without judgement, allows us to think freely without discrimination, and is an important element of giving us control over who knows what about us.</p>
                                    <p className="text-muted mb-2">Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
                                    <p className="text-muted fw-semibold">Website Name's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</p>
                                    <div className="text-end">
                                        <Button variant='secondary'>I'm Understand</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default PrivacyPolicy