import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Container, Row, Col, Table } from "react-bootstrap";

// Import Components
import Widgets from "./widgets";
import RealizedRate from "./realizedRate";
import BalanceOverview from "./balanceOverview";
import ContactLeads from "./contactLeads";
import EmailSent from "./emailSent";
import UsersActivity from "./usersActivity";
import SyncStatus from "./syncStatus";
import OpenDeals from "./openDeals";
import TasksList from "./tasksList";
import { Bar } from "pages/Components/apexcharts/Bar/Bar";
import { SimplePie } from "pages/Components/apexcharts/Pie/Pie";
import { Negative } from "pages/Components/apexcharts/Bar/Bar";
import { ColumnWithLable } from "pages/Components/apexcharts/Column/Column";
import { BasicColumn } from "pages/Components/apexcharts/Column/Column";
import axios from "axios";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import { Icon, divIcon, point } from "leaflet";

const Crm = () => {
  document.title = "Мөлхөө дашбоард | Zeer";

  const [top10, setTop10] = useState<any>();
  const [best10Cates, setBest10Cates] = useState<any>();
  const [best10Count, setBest10Count] = useState<any>([]);
  const [nuluulsonGenger, setNuluulsonGender] = useState<any>([]);
  const [cats, setCats] = useState<any>();
  const [male, setMale] = useState<any>([]);
  const [female, setFemale] = useState<any>([]);
  const [noloolsonIrgedAimag, setNoloolsonIrgedAimag] = useState<any>();
  const [pieChartData, setPieChartData] = useState<any>();
  const [pieCats, setPieCats] = useState<any>();
  const [pieValues, setPieValues] = useState<any>();
  const [herlenData, setHerlenData] = useState<any>();
  const [herlenCats, setHerlenCats] = useState<any>();
  const [herlenValues, setHerlenValues] = useState<any>();

  const position = [51.505, -0.09];

  const NoloolsonIrged = async () => {
    try {
      const best10 = await axios.post(
        "https://zeer.space/total/aimag/mulhuu/best10",
        {
          jsonrpc: 2.0,
          params: {
            aimag_id: "2",
          },
        }
      );
      setTop10(best10?.data?.result);
      // console.log(best10, "PeopleByAgeRes ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const NoloolsonIrgenGender = async () => {
    try {
      const resGender = await axios.post(
        "https://zeer.space/total/aimag/mulhuu/total",
        {
          jsonrpc: 2.0,
          params: {},
        }
      );
      setNuluulsonGender(resGender?.data?.result);
      // console.log(resGender, "resGender ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const NoloolsonIrgedAimag = async () => {
    try {
      const res = await axios.post("https://zeer.space/total/aimag/mulhuu", {
        jsonrpc: 2.0,
        params: {},
      });
      setNoloolsonIrgedAimag(res?.data?.result);
      // console.log(res, "resGender ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const FetchPieChartData = async () => {
    try {
      const res = await axios.post(
        "https://zeer.space/total/aimag/mulhuu/dornod/total",
        {
          jsonrpc: 2.0,
          params: {},
        }
      );
      setPieChartData(res?.data?.result);
      // console.log(res, "setPieChartData ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const FetchHerlenSum = async () => {
    try {
      const res = await axios.post(
        "https://zeer.space/total/aimag/mulhuu/dornod/herlen",
        {
          jsonrpc: 2.0,
          params: {},
        }
      );
      setHerlenData(res?.data?.result);
      // console.log(res, "setPieChartData ress");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    NoloolsonIrged();
    NoloolsonIrgenGender();
    NoloolsonIrgedAimag();
    FetchPieChartData();
    FetchHerlenSum();
  }, []);
  useEffect(() => {
    const newCats: any[] = [];
    const newCount: any[] = [];

    top10?.forEach((item: any) => {
      newCats.push(item?.name);
      newCount.push(item?.count);
    });

    setBest10Cates(newCats);
    setBest10Count(newCount);
  }, [top10]);
  useEffect(() => {
    const newCats: any[] = [];
    const newCount: any[] = [];

    pieChartData?.forEach((item: any) => {
      newCats.push(item?.name);
      newCount.push(item?.count);
    });

    setPieCats(newCats);
    setPieValues(newCount);
  }, [pieChartData]);
  useEffect(() => {
    const newCats: any[] = [];
    const newCount: any[] = [];

    herlenData?.forEach((item: any) => {
      newCats.push(item?.name);
      newCount.push(item?.count);
    });

    setHerlenCats(newCats);
    setHerlenValues(newCount);
  }, [herlenData]);
  useEffect(() => {
    const newCats: any[] = [];
    const newMale: any[] = [];
    const newFemale: any[] = [];

    nuluulsonGenger.forEach((item: any) => {
      newCats.push(item?.name);
      newMale.push(item?.male);
      newFemale.push(-item?.female);
    });

    setCats(newCats);
    setMale(newMale);
    setFemale(newFemale);
  }, [nuluulsonGenger]);

  const customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: require("../../../assets/icons/location.png"),
    iconSize: [38, 38], // size of the icon
  });

  const markers = [
    {
      geocode: [49.252965761601644, 113.36095559503991],
      popUp: "Баяндун",
    },
    {
      geocode: [48.05148468386486, 114.36728951197634],
      popUp: "Баянтүмэн",
    },
    {
      geocode: [49.10946129338363, 112.6255348449737],
      popUp: "Баян-Уул сум",
    },
    {
      geocode: [47.99520507489775, 113.93502463513909],
      popUp: "Булган сум",
    },
    {
      geocode: [49.15274979095658, 114.88605690650384],
      popUp: "Гурванзагал",
    },
    {
      geocode: [49.542546887681326, 114.39572587136561],
      popUp: "Дашбалбар",
    },
    {
      geocode: [46.95305687180716, 115.29314502016675],
      popUp: "Матад ",
    },
    {
      geocode: [48.50919527416904, 114.03203472772366],
      popUp: "Сэргэлэн ",
    },
    {
      geocode: [47.628232806241925, 118.62140153315138],
      popUp: "Халхгол ",
    },
    {
      geocode: [47.92050967100699, 112.964536802542],
      popUp: "Хөлөнбуйр ",
    },
    {
      geocode: [48.08212335931658, 114.53620032478537],
      popUp: "Хэрлэн ",
    },
    {
      geocode: [48.56795804003828, 113.23335857910868],
      popUp: "Цагаан-Овоо ",
    },
    {
      geocode: [49.87003335972583, 115.70187073234918],
      popUp: "Чулуунхороот ",
    },
    {
      geocode: [48.43719835333334, 114.8799132013825],
      popUp: `Чойбалсан  `,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Widgets /> */}
          <Row>
            <Row>
              <div
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  display: "inline-block",
                  textAlign: "center",
                  fontSize: "16px",
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "300px",
                }}
              >
                <div>Нийт нөлөөлсөн иргэд</div>
                <div style={{ fontWeight: "bold" }}>
                  {noloolsonIrgedAimag &&
                    noloolsonIrgedAimag[2]?.niit_noloolson_irgen}
                </div>
              </div>
              {noloolsonIrgedAimag?.map((item: any) => {
                if (item?.aimag_name) {
                  return (
                    <NumberItem title={item?.aimag_name} value={item?.count} />
                  );
                }
              })}
            </Row>

            {/* <Row style={{ marginTop: "20px" }}> */}
            <Col style={{ marginTop: "50px" }}>
              <div style={{ fontSize: "18px" }}>
                Хамгийн их нөлөөлөл үзүүлсэн хувь хүн /Шилдэг 10/{" "}
              </div>
              <Bar
                data={best10Count}
                categ={best10Cates}
                dataColors='["--tb-success"]'
              />
            </Col>
            <Col style={{ marginTop: "50px" }}>
              <div style={{ fontSize: "18px" }}>
                Дорнод аймгийн хэмжээнд сумдаар нөлөөлсөн хувь
              </div>
              {/* <SimplePie
                data={pieValues}
                categ={pieCats}
                dataC
                olors='["--tb-primary", "--tb-success", "--tb-warning", "--tb-danger", "--tb-info"]'
              /> */}
              <MapContainer
                center={[47.9215, 116.8467]}
                zoom={6}
                style={{ height: "90%", width: "100%" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {pieChartData?.map((marker: any) => {
                  if (marker?.name == "Баян-Уул сум") {
                    return (
                      <Marker
                        position={[49.10946129338363, 112.6255348449737]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Баяндун сум") {
                    return (
                      <Marker
                        position={[49.252965761601644, 113.36095559503991]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Баянтүмэн сум") {
                    return (
                      <Marker
                        position={[48.05148468386486, 114.36728951197634]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Булган сум") {
                    return (
                      <Marker
                        position={[47.99520507489775, 113.93502463513909]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Гурванзагал сум") {
                    return (
                      <Marker
                        position={[49.15274979095658, 114.88605690650384]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Дашбалбар сум") {
                    return (
                      <Marker
                        position={[49.542546887681326, 114.39572587136561]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Матад сум") {
                    return (
                      <Marker
                        position={[46.95305687180716, 115.29314502016675]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Сэргэлэн сум") {
                    return (
                      <Marker
                        position={[48.50919527416904, 114.03203472772366]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Халхгол сум") {
                    return (
                      <Marker
                        position={[47.628232806241925, 118.62140153315138]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Хэрлэн сум") {
                    return (
                      <Marker
                        position={[48.08212335931658, 114.53620032478537]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Хөлөнбуйр сум") {
                    return (
                      <Marker
                        position={[47.92050967100699, 112.964536802542]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Цагаан-Овоо сум") {
                    return (
                      <Marker
                        position={[48.56795804003828, 113.23335857910868]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Чойбалсан сум") {
                    return (
                      <Marker
                        position={[48.43719835333334, 114.8799132013825]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                  if (marker?.name == "Чулуунхороот сум") {
                    return (
                      <Marker
                        position={[49.87003335972583, 115.70187073234918]}
                        icon={customIcon}
                      >
                        <Popup>
                          {marker?.name} <br /> Нийт нөлөөлсөн: {marker?.count}
                        </Popup>
                      </Marker>
                    );
                  }
                })}
              </MapContainer>
            </Col>
            {/* </Row> */}
            {/* <Col xl={2}>
              <div style={{ fontSize: "14px" }}>
                Хамгийн их нөлөөлсөн хүмүүс
              </div>
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Сумын нэр</th>
                      <th scope="col">Мөлхөө тоо</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Дадал сум</th>
                      <td>12329</td>
                    </tr>
                    <tr>
                      <th scope="row">Дадал сум</th>
                      <td>1239</td>
                    </tr>
                    <tr>
                      <th scope="row">Дадал сум</th>
                      <td>1239</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col> */}

            <Row style={{ marginTop: "100px" }}>
              <Col>
                <div style={{ fontSize: "18px" }}>
                  Нийт нөлөөлсөн иргэд хүйс, насны бүлгээр харах
                </div>
                <Negative
                  data={[
                    {
                      name: "Эрэгтэй",
                      data: male,
                    },
                    {
                      name: "Эмэгтэй",
                      data: female,
                    },
                  ]}
                  dataColors='["--tb-primary", "--tb-success"]'
                  categ={cats}
                />
              </Col>

              <Col>
                {" "}
                <div style={{ fontSize: "18px" }}>
                  Дорнод, Хэрлэн сумын 11 багийн иргэд дээр нөлөөлсөн үзүүлэлт
                </div>
                <ColumnWithLable
                  data={herlenValues}
                  categ={herlenCats}
                  dataColors='["--tb-success"]'
                />
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Crm;

interface NumberItemProps {
  title: string;
  value: string;
}

const NumberItem: React.FC<NumberItemProps> = ({ title, value }) => {
  return (
    <div
      style={{
        border: "1px solid black",
        padding: "10px",
        display: "inline-block",
        textAlign: "center",
        fontSize: "16px",
        marginTop: "10px",
        marginRight: "10px",
        width: "300px",
      }}
    >
      <div>{title + "нийт нөлөөлсөн иргэд"}</div>
      <div style={{ fontWeight: "bold" }}>{value}</div>
    </div>
  );
};
