import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

// Import Components
import Widgets from "./widgets";
import LearningOverview from "./learningOverview";
import Instructors from "./instructors";
import Courses from "./courses";
import { BasicColumn } from "pages/Components/apexcharts/Column/Column";
import Overview from "./overview";
import axios from "axios";

const Learning = () => {
  document.title = "Learning | Steex Admin & Dashboard Template";

  //   herlen
  const [dataHerlen, setDataHerlen] = useState([]);
  const [herlenCategs, setHerlenCategs] = useState<any>();
  const [herlenVoted, setHerlenVoted] = useState<any>();
  const [herlenCared, setHerlenCared] = useState<any>();
  const [herlenNoVoted, setHerlenNoVoted] = useState<any>();

  //   dornod
  const [dataDornod, setDataDornod] = useState([]);
  const [dornodCategs, setDornodCategs] = useState<any>();
  const [dornodVoted, setDornodVoted] = useState<any>();
  const [dornodCared, setDornodCared] = useState<any>();
  const [dornodNoVoted, setDornodNoVoted] = useState<any>();

  //   hentii
  const [dataHentii, setDataHentii] = useState([]);
  const [hentiiCategs, setHentiiCategs] = useState<any>();
  const [hentiiVoted, setHentiiVoted] = useState<any>();
  const [hentiiCared, setHentiiCared] = useState<any>();
  const [hentiiNoVoted, setHentiiNoVoted] = useState<any>();

  //   Sukhbaatar
  const [dataSuhbaatar, setDataSuhbaatar] = useState([]);
  const [suhbaatarCategs, setSuhbaatarCategs] = useState<any>();
  const [suhbaatarVoted, setSuhbaatarVoted] = useState<any>();
  const [suhbaatarCared, setSuhbaatarCared] = useState<any>();
  const [suhbaatarNoVoted, setSuhbaatarNoVoted] = useState<any>();

  const FetchHerlen = async () => {
    const res = await axios.post(
      "https://zeer.space/total/aimag/mulhuu/voted/total",
      {
        jsonrpc: 2.0,
        params: {},
      }
    );
    setDataHerlen(res?.data?.result);
  };
  const FetchDornod = async () => {
    const res = await axios.post(
      "https://zeer.space/total/aimag/mulhuu/voted/total/dornod",
      {
        jsonrpc: 2.0,
        params: {},
      }
    );
    setDataDornod(res?.data?.result);
  };
  const FetchHentii = async () => {
    const res = await axios.post(
      "https://zeer.space/total/aimag/mulhuu/voted/total/hentii",
      {
        jsonrpc: 2.0,
        params: {},
      }
    );
    setDataHentii(res?.data?.result);
  };
  const FetchSuhbaatar = async () => {
    const res = await axios.post(
      "https://zeer.space/total/aimag/mulhuu/voted/total/sukhbaatar",
      {
        jsonrpc: 2.0,
        params: {},
      }
    );
    setDataHentii(res?.data?.result);
  };

  useEffect(() => {
    const newHerlenCats: any[] = [];
    const newVoted: any[] = [];
    const newCared: any[] = [];
    const newNoVoted: any[] = [];

    dataHerlen?.forEach((item: any) => {
      newHerlenCats.push(item?.name);
      newVoted.push(item?.voted);
      newCared.push(item?.taken_by_car);
      newNoVoted.push(item?.no_voted);
    });

    setHerlenCategs(newHerlenCats);
    setHerlenVoted(newVoted);
    setHerlenCared(newCared);
    setHerlenNoVoted(newNoVoted);
  }, [dataHerlen]);

  useEffect(() => {
    const newCats: any[] = [];
    const newVoted: any[] = [];
    const newCared: any[] = [];
    const newNoVoted: any[] = [];

    dataDornod?.forEach((item: any) => {
      newCats.push(item?.name);
      newVoted.push(item?.voted);
      newCared.push(item?.taken_by_car);
      newNoVoted.push(item?.no_voted);
    });

    setDornodCategs(newCats);
    setDornodVoted(newVoted);
    setDornodCared(newCared);
    setDornodNoVoted(newNoVoted);
  }, [dataDornod]);
  useEffect(() => {
    const newCats: any[] = [];
    const newVoted: any[] = [];
    const newCared: any[] = [];
    const newNoVoted: any[] = [];

    dataHentii?.forEach((item: any) => {
      newCats.push(item?.name);
      newVoted.push(item?.voted);
      newCared.push(item?.taken_by_car);
      newNoVoted.push(item?.no_voted);
    });

    setHentiiCategs(newCats);
    setHentiiVoted(newVoted);
    setHentiiCared(newCared);
    setHentiiNoVoted(newNoVoted);
  }, [dataHentii]);
  useEffect(() => {
    const newCats: any[] = [];
    const newVoted: any[] = [];
    const newCared: any[] = [];
    const newNoVoted: any[] = [];

    dataSuhbaatar?.forEach((item: any) => {
      newCats.push(item?.name);
      newVoted.push(item?.voted);
      newCared.push(item?.taken_by_car);
      newNoVoted.push(item?.no_voted);
    });

    setSuhbaatarCategs(newCats);
    setSuhbaatarVoted(newVoted);
    setSuhbaatarCared(newCared);
    setSuhbaatarNoVoted(newNoVoted);
  }, [dataSuhbaatar]);

  useEffect(() => {
    FetchHerlen();
    FetchDornod();
    FetchHentii();
    FetchSuhbaatar();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Row>
              <div>Хэрлэн сум</div>
              <BasicColumn
                data={[
                  {
                    name: "Санал өгөөгүй",
                    data: herlenNoVoted,
                  },
                  {
                    name: "Унаанд суусан",
                    data: herlenCared,
                  },
                  {
                    name: "Санал өгсөн",
                    data: herlenVoted,
                  },
                ]}
                categ={herlenCategs}
                dataColors='["--tb-danger", "--tb-primary", "--tb-success"]'
              />
            </Row>
            <Row>
              <div>Дорнод аймаг</div>
              <BasicColumn
                data={[
                  {
                    name: "Санал өгөөгүй",
                    data: dornodNoVoted,
                  },
                  {
                    name: "Унаанд суусан",
                    data: dornodCared,
                  },
                  {
                    name: "Санал өгсөн",
                    data: dornodVoted,
                  },
                ]}
                categ={dornodCategs}
                dataColors='["--tb-danger", "--tb-primary", "--tb-success"]'
              />
            </Row>
          </Row>
          <Row>
            {/* <Col>
              <div>Хэнтий аймаг</div>
              <BasicColumn
                data={[
                  {
                    name: "Санал өгөөгүй",
                    data: hentiiNoVoted,
                  },
                  {
                    name: "Унаанд суусан",
                    data: hentiiCared,
                  },
                  {
                    name: "Санал өгсөн",
                    data: hentiiVoted,
                  },
                ]}
                categ={hentiiCategs}
                dataColors='["--tb-danger", "--tb-primary", "--tb-success"]'
              />
            </Col> */}
            {/* <Col>
              <div>Сүхбаатар аймаг</div>
              <BasicColumn
                data={[
                  {
                    name: "Санал өгөөгүй",
                    data: suhbaatarNoVoted,
                  },
                  {
                    name: "Унаанд суусан",
                    data: suhbaatarCared,
                  },
                  {
                    name: "Санал өгсөн",
                    data: suhbaatarVoted,
                  },
                ]}
                categ={suhbaatarCategs}
                dataColors='["--tb-danger", "--tb-primary", "--tb-success"]'
              />
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Learning;
