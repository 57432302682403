import React, { useEffect } from "react";
import avatar1 from "assets/images/users/avatar-1.jpg";
import avatar2 from "assets/images/users/avatar-2.jpg";
import { useSelector } from "react-redux";

import getChartColorsArray from "../../../../Common/ChartsDynamicColor";
import Chart from "react-apexcharts";
import { createSelector } from "reselect";

const Scatter = ({ dataColors }: any) => {
  var chartScatterBasicColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const series = [
    {
      name: "SAMPLE A",
      data: [
        [16.4, 5.4],
        [21.7, 2],
        [25.4, 3],
        [19, 2],
        [10.9, 1],
        [13.6, 3.2],
        [10.9, 7.4],
        [10.9, 0],
        [10.9, 8.2],
        [16.4, 0],
        [16.4, 1.8],
        [13.6, 0.3],
        [13.6, 0],
        [29.9, 0],
        [27.1, 2.3],
        [16.4, 0],
        [13.6, 3.7],
        [10.9, 5.2],
        [16.4, 6.5],
        [10.9, 0],
        [24.5, 7.1],
        [10.9, 0],
        [8.1, 4.7],
        [19, 0],
        [21.7, 1.8],
        [27.1, 0],
        [24.5, 0],
        [27.1, 0],
        [29.9, 1.5],
        [27.1, 0.8],
        [22.1, 2],
      ],
    },
    {
      name: "SAMPLE B",
      data: [
        [36.4, 13.4],
        [1.7, 11],
        [5.4, 8],
        [9, 17],
        [1.9, 4],
        [3.6, 12.2],
        [1.9, 14.4],
        [1.9, 9],
        [1.9, 13.2],
        [1.4, 7],
        [6.4, 8.8],
        [3.6, 4.3],
        [1.6, 10],
        [9.9, 2],
        [7.1, 15],
        [1.4, 0],
        [3.6, 13.7],
        [1.9, 15.2],
        [6.4, 16.5],
        [0.9, 10],
        [4.5, 17.1],
        [10.9, 10],
        [0.1, 14.7],
        [9, 10],
        [12.7, 11.8],
        [2.1, 10],
        [2.5, 10],
        [27.1, 10],
        [2.9, 11.5],
        [7.1, 10.8],
        [2.1, 12],
      ],
    },
    {
      name: "SAMPLE C",
      data: [
        [21.7, 3],
        [23.6, 3.5],
        [24.6, 3],
        [29.9, 3],
        [21.7, 20],
        [23, 2],
        [10.9, 3],
        [28, 4],
        [27.1, 0.3],
        [16.4, 4],
        [13.6, 0],
        [19, 5],
        [22.4, 3],
        [24.5, 3],
        [32.6, 3],
        [27.1, 4],
        [29.6, 6],
        [31.6, 8],
        [21.6, 5],
        [20.9, 4],
        [22.4, 0],
        [32.6, 10.3],
        [29.7, 20.8],
        [24.5, 0.8],
        [21.4, 0],
        [21.7, 6.9],
        [28.6, 7.7],
        [15.4, 0],
        [18.1, 0],
        [33.4, 0],
        [16.4, 0],
      ],
    },
  ];
  const options: any = {
    chart: {
      zoom: {
        enabled: true,
        type: "xy",
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      tickAmount: 10,
      labels: {
        formatter: function (val: any) {
          return parseFloat(val).toFixed(1);
        },
      },
    },
    yaxis: {
      tickAmount: 7,
    },
    colors: chartScatterBasicColors,
  };

  return (
    <React.Fragment>
      <Chart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="scatter"
        height={350}
      />
    </React.Fragment>
  );
};

const Datetime = ({ dataColors }: any) => {
  // Define the age groups
  const ageGroups = ["18-24", "25-34", "26-40", "40-65"];

  // Define static series data for each age group
  const seriesData = [
    {
      name: "18-24",
      data: [
        { x: new Date("2020-06-18T08:00:00Z").getTime(), y: 30 },
        { x: new Date("2020-06-18T09:00:00Z").getTime(), y: 32 },
        { x: new Date("2020-06-18T10:00:00Z").getTime(), y: 28 },
        { x: new Date("2020-06-18T14:30:00Z").getTime(), y: 10 },
      ],
    },
    {
      name: "25-34",
      data: [
        { x: new Date("2020-06-18T08:00:00Z").getTime(), y: 35 },
        { x: new Date("2020-06-18T09:00:00Z").getTime(), y: 33 },
        { x: new Date("2020-06-18T10:00:00Z").getTime(), y: 36 },
      ],
    },
    {
      name: "26-40",
      data: [
        { x: new Date("2020-06-18T08:20:00Z").getTime(), y: 30 },
        { x: new Date("2020-06-18T09:40:00Z").getTime(), y: 32 },
        { x: new Date("2020-06-18T11:00:00Z").getTime(), y: 28 },
        { x: new Date("2020-06-18T15:30:00Z").getTime(), y: 10 },
      ],
    },
    {
      name: "40-65",
      data: [
        { x: new Date("2020-06-18T17:20:00Z").getTime(), y: 30 },
        { x: new Date("2020-06-18T18:40:00Z").getTime(), y: 32 },
        { x: new Date("2020-06-18T20:00:00Z").getTime(), y: 28 },
      ],
    },
  ];

  // Options for the chart
  const options: any = {
    chart: {
      zoom: {
        type: "xy",
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM",
          day: "dd",
          hour: "HH", // Display only hours
        },
      },
    },
    yaxis: {
      max: 70,
    },
    colors: getChartColorsArray(dataColors),
  };

  return (
    <React.Fragment>
      <Chart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={seriesData}
        type="scatter"
        height={350}
      />
    </React.Fragment>
  );
};

const ImagesChart = ({ dataColors }: any) => {
  var chartScatterImagesColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "User A",
      data: [
        [16.4, 5.4],
        [21.7, 4],
        [25.4, 3],
        [19, 2],
        [10.9, 1],
        [13.6, 3.2],
        [10.9, 7],
        [10.9, 8.2],
        [16.4, 4],
        [13.6, 4.3],
        [13.6, 12],
        [29.9, 3],
        [10.9, 5.2],
        [16.4, 6.5],
        [10.9, 8],
        [24.5, 7.1],
        [10.9, 7],
        [8.1, 4.7],
      ],
    },
    {
      name: "User B",
      data: [
        [6.4, 5.4],
        [11.7, 4],
        [15.4, 3],
        [9, 2],
        [10.9, 11],
        [20.9, 7],
        [12.9, 8.2],
        [6.4, 14],
        [11.6, 12],
      ],
    },
  ];

  const options: any = {
    chart: {
      animations: {
        enabled: !1,
      },
      zoom: {
        enabled: !1,
      },
      toolbar: {
        show: !1,
      },
    },
    colors: chartScatterImagesColors,
    xaxis: {
      tickAmount: 10,
      min: 0,
      max: 40,
    },
    yaxis: {
      tickAmount: 7,
    },
    markers: {
      size: 20,
    },
    fill: {
      type: "image",
      opacity: 1,
      image: {
        src: [avatar1, avatar2],
        width: 40,
        height: 40,
      },
    },
    legend: {
      labels: {
        useSeriesColors: !0,
      },
      markers: {
        customHTML: [
          function () {
            return "";
          },
          function () {
            return "";
          },
        ],
      },
    },
  };

  return (
    <React.Fragment>
      <Chart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="scatter"
        height={350}
      />
    </React.Fragment>
  );
};

export { Scatter, Datetime, ImagesChart };
