//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";

import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";
import axios from "axios";
export const loginUser =
  (userData: any, history: any) => async (dispatch: any) => {
    try {
      // Simulating a successful login based on user data
      if (userData.email && userData.password) {
        // Simulating a successful response data
        const responseData = {
          email: userData.email /* other user data if needed */,
        };
        const res = await axios.post("https://zeer.space/user/login", {
          jsonrpc: 2.0,
          params: { login: userData.email, password: userData.password },
        });
        console.log(res, "login res");
        if (res?.data?.result) {
          // Store user data in localStorage
          localStorage.setItem("authUser", JSON.stringify(responseData));
          localStorage.setItem("uid", JSON.stringify(res?.data?.result?.uid));

          // Dispatch login success action
          dispatch(loginSuccess(responseData));

          // Redirect to the dashboard
          history("/dashboard");
        } else {
          alert("Нэвтрэх нэр эсвэл нууц үг буруу байна!");
        }
      } else {
        // If email or password is missing, dispatch an error
        dispatch(apiError("Email or password missing"));
      }
    } catch (error) {
      // Dispatch any caught errors as API errors
      dispatch(apiError(error));
    }
  };

export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      let fireBaseBackend = getFirebaseBackend();
      const response = fireBaseBackend.logout;
      dispatch(logoutUserSuccess(response));
    } else {
      dispatch(logoutUserSuccess(true));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin =
  (type: any, history: any) => async (dispatch: any) => {
    try {
      let response;

      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const fireBaseBackend = getFirebaseBackend();
        response = fireBaseBackend.socialLoginUser(type);
      }
      //  else {
      //   response = postSocialLogin(data);
      // }

      const socialdata = await response;
      if (socialdata) {
        localStorage.setItem("authUser", JSON.stringify(socialdata));
        dispatch(loginSuccess(socialdata));
        history("/dashboard");
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
