import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Container, Row } from "react-bootstrap";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

//img
import pImg1 from "assets/images/products/img-1.png";
import pImg7 from "assets/images/products/img-7.png";
import pImg8 from "assets/images/products/img-8.png";
import pImg10 from "assets/images/products/img-10.png";
import pImg6 from "assets/images/products/img-6.png";

import ShopingInformation from "./ShopingInfo";
import ProductRatting from "./ProductRatting";
import { ToastContainer } from "react-toastify";
import ApexTree, { ApexTreeOptions } from "apextree";

//img
export const productdetailsImg = [
  { id: 1, img: pImg1 },
  { id: 2, img: pImg7 },
  { id: 3, img: pImg8 },
  { id: 4, img: pImg10 },
  { id: 5, img: pImg6 },
];

const ProductDetails = () => {
  document.title = "Products Overview | Steex - Admin & Dashboard Template";

  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  useEffect(() => {
    const data = {
      id: "ms",
      data: {
        name: "Margret Swanson",
        desc: "tailbar",
      },
      options: {
        nodeBGColor: "#cdb4db",
        nodeBGColorHover: "#cdb4db",
      },
      children: [
        {
          id: "mh",
          data: {
            name: "Mark Hudson",
          },
          options: {
            nodeBGColor: "#ffafcc",
            nodeBGColorHover: "#ffafcc",
          },
          children: [
            {
              id: "kb",
              data: {
                name: "Karyn Borbas",
              },
              options: {
                nodeBGColor: "#f8ad9d",
                nodeBGColorHover: "#f8ad9d",
              },
            },
            {
              id: "cr",
              data: {
                name: "Chris Rup",
              },
              options: {
                nodeBGColor: "#c9cba3",
                nodeBGColorHover: "#c9cba3",
              },
            },
          ],
        },
        {
          id: "cs",
          data: {
            name: "Chris Lysek",
          },
          options: {
            nodeBGColor: "#00afb9",
            nodeBGColorHover: "#00afb9",
          },
          children: [
            {
              id: "Noah_Chandler",
              data: {
                name: "Noah Chandler",
              },
              options: {
                nodeBGColor: "#84a59d",
                nodeBGColorHover: "#84a59d",
              },
            },
            {
              id: "Felix_Wagner",
              data: {
                name: "Felix Wagner",
              },
              options: {
                nodeBGColor: "#0081a7",
                nodeBGColorHover: "#0081a7",
              },
              children: [
                {
                  id: "Noah_Chandler",
                  data: {
                    name: "Noah Chandler",
                  },
                  options: {
                    nodeBGColor: "#84a59d",
                    nodeBGColorHover: "#84a59d",
                  },
                },
                {
                  id: "Felix_Wagner",
                  data: {
                    name: "Felix Wagner",
                  },
                  options: {
                    nodeBGColor: "#0081a7",
                    nodeBGColorHover: "#0081a7",
                  },
                },
              ],
            },
          ],
        },
      ],
    };
    const options = {
      contentKey: "data",
      width: 800,
      height: 600,
      nodeWidth: 150,
      nodeHeight: 100,
      fontColor: "#fff",
      borderColor: "#333",
      childrenSpacing: 50,
      siblingSpacing: 20,
      direction: "top",
      enableExpandCollapse: true,
      nodeTemplate: (content: any) =>
        `<div style='display: flex;flex-direction: column;gap: 10px;justify-content: center;align-items: center;height: 100%;'>
          <div style="font-weight: bold; font-family: Arial; font-size: 14px">${
            content.name
          }</div>
          <div style="font-weight: bold; font-family: Arial; font-size: 14px">${
            content.desc ? content.desc : ""
          }</div>
         </div>`,
      canvasStyle: "border: 1px solid black;background: #f6f6f6;",
      enableToolbar: true,
    };

    const svgTreeElement = document.getElementById("svg-tree");

    if (svgTreeElement) {
      const tree = new ApexTree(svgTreeElement, options);
      tree.render(data);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Product Overview" pageTitle="Ecommerce" />
          <Row>
            <div id="svg-tree"></div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ProductDetails;
